<template>
    <div class="logins">
        <div class="login-main">
            <div class="login-form">
                <div class="item-form">
                    <el-input placeholder="请输入电话" @blur="phoneValidate" v-model="phone">
                        <span slot="prepend">手机号</span>
                    </el-input>
                </div>
                <div class="item-form">
                    <el-input placeholder="请输入密码" v-model="password" show-password>
                        <span slot="prepend">密码</span>
                    </el-input>
                </div>
                <div class="item-form">
                    <el-input placeholder="请输入验证码" v-model="loginForm.captcha">
                        <span slot="prepend">验证码</span>
                        <img slot="append" @click="getCaptchaData" class="imgCode" :src="captchaImage" alt="Captcha Image">
                    </el-input>
                </div>
                <div class="item-btn">
                    <el-button type="primary" @click="onLogin">登录</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import encrypt from "@/util/encrypt"
    import { getCaptcha, login } from "@/api/login"
    import { setToken } from '@/util/auth'
    export default {
        name: "login",
        emits:["handleSuccess"],
        data() {
            return {
                captchaImage:"",
                phone:"",
                password: '',
                loginForm: {
                    phone: '',
                    password: '',
                    captcha:"",
                    uuid:"",
                    enableCaptcha: true
                },
            };
        },
        mounted() {
            this.getCaptchaData()
        },
        methods: {
            //手机号码验证
            phoneValidate(){
                let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if(reg.test(this.phone) === false){
                    this.phone = "";
                    this.$message.error("请输入正确的手机号码");
                }
            },
            //获取图片验证码
            getCaptchaData(){
                getCaptcha().then(res=>{
                    this.captchaImage = res.data.captcha;
                    this.loginForm.uuid = res.data.uuid;
                }).catch(() => {});
            },
            //用户登录
            onLogin(){
                if(this.phone === ""){
                    this.$message.error("手机号码不能为空");
                }else if(this.password === ""){
                    this.$message.error("密码不能为空");
                }else if(this.loginForm.captcha === ""){
                    this.$message.error("手机验证码不能为空");
                }else{
                    this.loginForm.phone = encrypt.aesUtil.encrypt(this.phone)
                    this.loginForm.password = encrypt.aesUtil.encrypt(this.password)
                    login(this.loginForm).then(res=>{
                        if(res.code === 200){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            setToken(res.data);
                            this.onCancel();
                            // this.getUserInfo();
                        }else{
                            this.$message.error(res.msg);
                            this.getCaptchaData()
                        }
                    }).catch(() => {});
                }
            },
            onCancel(){
                this.$emit("handleSuccess",false)
            },
        }
    }
</script>

<style lang="scss">
    .logins{
        .login-main{
            background: #fff;
            border-radius: 10px;
            .login-form{
                width: 95%;
                margin: 0 auto;
                .item-form{
                    padding: 15px 0;
                    span{
                        display: block;
                        width: 45px;
                    }
                    .imgCode{
                        height: 34px;
                    }
                }
                .item-btn{
                    display: flex;
                    justify-content: space-between;
                    padding-top: 20px;
                    .el-button{
                        width: 100%;
                    }
                }
            }

        }
    }
</style>

<template>
    <div class="logins">
        <div class="login-main">
            <div class="login-form">
                <div class="item-form">
                    <el-input placeholder="请输入原密码" v-model="loginForm.oldPsw" show-password>
                        <span slot="prepend">原密码</span>
                    </el-input>
                </div>
                <div class="item-form">
                    <el-input placeholder="请输入新密码" v-model="loginForm.newPsw" show-password>
                        <span slot="prepend">新密码</span>
                    </el-input>
                </div>
                <div class="item-btn">
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { updatePwd } from "@/api/login"
    export default {
        name: "editPassword",
        emits:["handleSuccess"],
        data() {
            return {
                loginForm: {
                    oldPsw: '',
                    newPsw: '',
                    isEncipher:true
                },
            };
        },
        methods: {
            //提交修改密码
            onSubmit(){
                updatePwd(this.loginForm).then(res=>{
                    if(res.code == 200){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.$emit("handleSuccess",false)
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(() => {});
            },
            onCancel(){
                this.$emit("handleSuccess",false)
            }
        }
    }
</script>

<style lang="scss">
    .logins{
        .login-main{
            background: #fff;
            border-radius: 10px;
            .login-form{
                padding: 15px;
                .item-form{
                    padding: 15px 0;
                    span{
                        display: block;
                        width: 45px;
                    }
                    .code{
                        width: 110px;
                        padding: 9.5px 10px;
                        margin: 0px -20px;
                        cursor: pointer;
                        text-align: center;
                    }
                }
                .item-btn{
                    display: flex;
                    justify-content: space-between;
                    padding-top: 20px;
                    a{
                        width: 50%;
                        background: #3291f5;
                        color: #fff;
                        text-decoration: none;
                        display: block;
                        text-align: center;
                        line-height: 37px;
                        margin-left: 10px;
                        border-radius: 4px;
                    }
                }
            }

        }
    }
</style>

<template>
    <div class="header">
        <div class="header-main">
            <div class="log">
<!--                <img src="../../assets/login_bg.png">-->
                <span>贵州同天科技有限公司</span>
            </div>
            <div class="nav">
                <div :class="item.path == path?'nav-list nav-active':'nav-list'" v-for="item in data">
                    <router-link :to="item.path" class="link-text">{{item.name}}</router-link>
                </div>
            </div>
            <div class="login">
                <div v-if="userName === ''">
                    <span class="login-btn" @click="handleClick('login')">登录</span>
                    <span class="login-btn" @click="handleClick('register')">注册</span>
                </div>
                <div v-else>
                    <el-dropdown @command="handleCommand">
                        <div class="el-dropdown-link">
                            <span :style="{color:'#fff'}">欢迎您：{{userName}}</span>
                            <i :style="{color:'#fff'}" class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item  icon="el-icon-user" command="user">个人信息</el-dropdown-item>
                            <el-dropdown-item  icon="el-icon-edit" command="editPassword">修改密码</el-dropdown-item>
                            <el-dropdown-item  icon="el-icon-switch-button" command="onLogout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <handleUser :dialogHandle="dialogHandle" :handleType="handleType" @closeEvent="handleClose"></handleUser>
    </div>
</template>

<script>
    import { getUser, setUserInfo, removeToken, removeUserInfo } from "@/util/auth"
    import { logout } from "@/api/login"
    import { getUserInfo } from "@/api/user"
    import handleUser from "../../views/login/index"
    export default {
        name: "hearder",
        data(){
            return {
                loginPath:["userInfo","sign","student"],
                dialogHandle:false,
                handleType:'',
                path:"/",
                token:"",
                userName:"",
                headers:true,
                data:[
                    {name:"首页",path:"/"},
                    {name:"主题展示",path:"/activeInfo"},
                    {name:"教师信息",path:"/teacherInfo"},
                    // {name:"主题展示",path:"/motif"},
                    // {name:"课程信息",path:"/classInfo"},
                    {name:"基地信息",path:"/baseInfo"},
                    // {name:"基金信息",path:"/fundInfo"},
                ]
            }
        },
        components:{handleUser},
        mounted() {
            this.path = this.$route.path;
            if(getUser()){
                this.userName = JSON.parse(getUser())["name"]
            }else{
                this.userName = ""
            }
        },
        watch: {
            $route(to, from) {
                this.path = to.path;
                if(getUser()){
                    this.userName = JSON.parse(getUser())["name"]
                }else{
                    this.userName = ""
                }
            }
        },
        methods: {
            handleCommand(command){
                if(command === "user"){
                    this.$router.push("/userInfo")
                }else if(command === "editPassword"){
                    this.handleClick('editPassword')
                }else if(command === "onLogout"){
                    this.onLogout();
                }
            },
            handleClick(type){
                this.dialogHandle = true;
                this.handleType = type
            },
            handleClose(type){
                this.dialogHandle = false;
                this.handleType = "";
                if(type === "login"){
                    getUserInfo({isToken:true}).then(res=>{
                        if(res.code === 200){
                            setUserInfo(res.data)
                            this.userName =res.data.name !== ""?res.data.name:res.data.phone
                        }
                    })
                }
            },
            onLogout(){
                this.$alert('确定退出登录吗？该操作会导致部分功能不可见', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        logout({isToken:true}).then(res=>{
                            removeToken();
                            removeUserInfo();
                            this.userName = "";
                            if(this.path !== "/"){
                                this.$router.push('/');
                            }
                            this.$message('退出成功！');
                        })
                    }
                });

            }
        }
    }
</script>

<style lang="scss">
    .header{
        background: #5c5bd2;
        border-bottom: 1px solid #eee;
        .header-main{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            .log{
                display: flex;
                align-items: center;
                margin-right: 3rem;
                img{
                    width: 40px;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    padding: 10px;
                }
                span{
                    font-size: 22px;
                    margin-left: 15px;
                    color:#fff;
                }
            }
            .nav{
                margin: 0;
                display: flex;
                flex: 1;
                list-style: none;
                .nav-list{
                    padding: 15px 20px;
                    color:#fff;
                    cursor: pointer;
                    .link-text{
                        color:#fff;
                        text-decoration: none;
                        font-size: 20px;
                    }
                }
                .nav-active{
                    color: #f1890b;
                    .link-text{
                        color:#f1890b;
                    }
                }
            }
            .login-btn{
                color:#fff;
                text-decoration: none;
                margin-left: 15px;
                cursor: pointer;
            }
        }
    }
    .item-menu{
        color:#333;
        text-decoration: none;
    }
</style>

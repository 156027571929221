import axios from "axios";
import encrypt from "@/util/encrypt";
import { getToken } from "@/util/auth";

const defaultEnv = require("@/config/env");

const baseurl = defaultEnv.baseUrl;

axios.defaults.headers["Content-Type"] = "application/json";
// 创建axios实例
const instance = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseurl,
  // 超时
  timeout: 50000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (config.data) {
      if (config.data.isToken === true) {
        config.headers["Authorization-api-user"] = `Bearer ${getToken()}`;
      }
      if (config.data.isEncipher === true) {
        // delete config.data.isEncipher;
        config.data = encrypt.aesUtil.encrypt(config.data);
      }
    } else if (config.params) {
      if (config.params.isToken === true) {
        config.headers["Authorization-api-user"] = `Bearer ${getToken()}`;
      }
      if (config.params.isEncipher === true) {
        // delete config.data.isEncipher;
        config.params = encrypt.aesUtil.encrypt(config.params);
      }
    }

    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return error;
    // return Promise.reject("dasdadasda",error)
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // eslint-disable-next-line no-unused-expressions
    if (response.status === 200) {
      let resData = response.data;
      let message = resData.msg || "未知错误";
      let decryptType = resData.decryptType;
      let data = resData.data;
      if (decryptType) {
        decryptType = encrypt.aesUtil.decrypt(decryptType);
        if (decryptType === "AES") {
          message = encrypt.aesUtil.decrypt(message);
          data = encrypt.aesUtil.decrypt(data);
        }
        resData = {
          code: resData.code,
          msg: message,
          data: data,
        };
      }
      return resData;
    } else if (response.status === 401) {
      this.$router.push("/login");
    } else {
      console.error(response);
    }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return error;
    // return Promise.reject(error)
  }
);

export default instance;

import Vue from 'vue'
import VueRouter from 'vue-router'
import { removeToken, removeUserInfo } from "@/util/auth"
import { getUserInfo } from "@/api/user"
import {MessageBox} from "element-ui";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/index.vue'),
  },{
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('../views/mine/index.vue'),
    redirect: "userInfo",
    children:[
      {
        path: "/",
        name: "user",
        component: () => import("../views/mine/user/index.vue"),
        meta:{isLogin:true}
      },{
        path: "/student",
        name: "student",
        component: () => import("../views/mine/student/index.vue"),
        meta:{isLogin:true}
      },{
        path: "/sign",
        name: "sign",
        component: () => import("../views/mine/sign/index.vue"),
        meta:{isLogin:true}
      },
    ]
  },{
    path: '/activeInfo',
    name: 'activeInfo',
    component: () => import('../views/activeInfo/index.vue')
  },{
    path: '/activeInfo/details',
    name: 'activeInfo',
    component: () => import('../views/activeInfo/details.vue')
  },{
    path: '/baseInfo',
    name: 'baseInfo',
    component: () => import('../views/baseInfo/index.vue')
  },{
    path: '/baseInfo/details',
    name: 'baseInfo',
    component: () => import('../views/baseInfo/details.vue')
  },{
    path: '/teacherInfo',
    name: 'teacherInfo',
    component: () => import('../views/teacherInfo/index.vue')
  },{
    path: '/teacherInfo/details',
    name: 'teacherInfo',
    component: () => import('../views/teacherInfo/details.vue')
  },{
    path: '/classInfo',
    name: 'classInfo',
    component: () => import('../views/classInfo/index.vue')
  },{
    path: '/classInfo/details',
    name: 'classInfo',
    component: () => import('../views/classInfo/details.vue')
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.isLogin){
    getUserInfo({isToken:true}).then(res=>{
      if(res.code === 401){
        removeToken();
        removeUserInfo();
        MessageBox.confirm('您还没有登录或者登录已超时，请重新登录', "系统提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          type: "warning"
        })
        next("/");
      }else{
        next();
      }
    })
  }else{
    next();
  }
});

export default router

<template>
    <div class="noData">
        <img src="../../assets/404.png" alt="">
        <p>暂时没有数据</p>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style lang="scss">
    .noData{
        width: 100%;
        text-align: center;
        padding: 15px 0;
        background: #fff;
        p{
            font-size: 18px;
            color:#5f5f5f;
        }
    }
</style>

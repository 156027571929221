// 配置编译环境和线上环境之间的切换

let baseUrl = "",
  publicPath = "";
const env = process.env;
let interfaceUrl = "/";
if (env.NODE_ENV === "development") {
  baseUrl = `/api`; // 开发环境地址
  publicPath = "/";
  interfaceUrl = "http://localhost:8080";
} else if (env.NODE_ENV === "production") {
  baseUrl = `/api`; // 生产环境地址
  publicPath = "/";
  interfaceUrl = "http://www.gzttkjnet.com/api";
}

module.exports = {
  baseUrl,
  publicPath,
  env,
  interfaceUrl,
};

<template>
  <div id="app" ref="app">
    <div ref="header" ><hearder></hearder></div>
    <div id="content" :style="{height:contentHeight+'px'}">
      <div :style="{minHeight:bodyHeight+'px'}"><router-view/></div>
      <div ref="footer"> <foader></foader></div>
    </div>
  </div>
</template>
<script>
  import hearder from './components/hearder/hearder.vue'
  import foader from "./components/footer/index"
  export default {
    name: 'App',
    data(){
      return {
        contentHeight:0,
        bodyHeight:0
      }
    },
    mounted() {
      const headerHeight = this.$refs.header.offsetHeight; // 获取标签高度
      const footerHeight = this.$refs.footer.offsetHeight; // 获取标签高度
      const appHeight = window.innerHeight; // 获取标签高度
      this.contentHeight = appHeight - headerHeight
      this.bodyHeight = appHeight - headerHeight - footerHeight
    },
    components: {
      hearder,foader
    }
  }
</script>
<style lang="scss">
  html,body{
    width: 100%;
    height:100%;
  }
  body{
    margin: 0;
    background: #f5f5f5;
    #app{
      width: 100%;
      height: 100%;
      #content{
        overflow-y: auto;
      }
    }
  }
</style>

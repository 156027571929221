import request from '@/util/request'
//用户注册
export function register (data) {
    return request({
        url: '/api/user/register',
        method: 'post',
        data:data,
    })
}
//验证手机号码是否注册
export function checkPhone (data) {
    return request({
        url: '/api/user/checkPhone/'+data,
        method: 'get',
    })
}
//注册时获取手机验证码
export function getRegisterCode (data) {
    return request({
        url: '/api/user/getRegisterCode/'+data,
        method: 'get',
    })
}

//获取图片验证码
export function getCaptcha () {
    return request({
        url: '/api/getCaptcha',
        method: 'get',
    })
}
//用户登录
export function login (data) {
    return request({
        url: '/api/auth/login',
        method: 'post',
        data:data
    })
}
//找回密码回去手机验证码
export function getForgotCode (data) {
    return request({
        url: '/api/user/getForgotCode/'+data,
        method: 'get',
    })
}
//找回密码提交
export function forgotPwd (data) {
    return request({
        url: '/api/user/forgotPwd',
        method: 'post',
        data:data
    })
}

//修改密码提交
export function updatePwd (data) {
    return request({
        url: '/api/user/updatePwd',
        method: 'post',
        data:data
    })
}
//退出登录
export function logout (data) {
    return request({
        url: '/api/auth/logout',
        method: 'get',
        data:data
    })
}

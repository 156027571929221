<template>
    <el-dialog :visible.sync="dialogHandle"
               :close-on-click-modal="false"
               :modal = "false" v-dialogDrag
               :title="title" top="20vh" width="480px" :before-close="handleClose">
        <div class="handleUser">
            <div class="hand-content">
                <div v-if="hand_type==='login'">
                    <login @handleSuccess="handSuccess"></login>
                    <div class="text">
                        <span @click="switchover('register')">还没有账号？立即注册</span>
                        <span @click="switchover('retrievePassword')">忘记密码？立即找回</span>
                    </div>
                </div>
                <div v-if="hand_type==='register'">
                    <register @handleSuccess="handSuccess"></register>
                </div>
                <editPassword @handleSuccess="handSuccess" v-if="hand_type==='editPassword'"></editPassword>
                <retrievePassword @handleSuccess="handSuccess" v-if="hand_type==='retrievePassword'"></retrievePassword>
            </div>
        </div>
    </el-dialog>

</template>

<script>
    import login from "../../views/login/login"
    import register from "../../views/login/register"
    import editPassword from "../../views/login/editPassword"
    import retrievePassword from "../../views/login/retrievePassword"
    export default {
        name: "index",
        emits:["closeEvent"],
        props:{
            dialogHandle:{
                type:Boolean,
                default:false
            },
            handleType:{
                type:String,
                default: ''
            }
        },
        components:{login,register,editPassword,retrievePassword},
        data(){
            return {
                hand_type:"login",
                title:""
            }
        },
        watch:{
            handleType:{
                handler(newVal){
                    this.hand_type = newVal;
                    this.title = newVal === "login"?'用户登录': newVal === "register"?'用户注册': newVal === "editPassword"?'修改密码':'找回密码'
                },
                deep:true,
                immediate:true
            }
        },
        methods:{
            handleClose(){
                this.$emit("closeEvent",this.hand_type);
                this.hand_type = "";
            },
            handSuccess(){
                this.handleClose();
            },
            switchover(type){
                this.hand_type = type;
                this.title = type === "login"?'用户登录': type === "register"?'用户注册': type === "editPassword"?'修改密码':'找回密码'
            }
        }
    }
</script>

<style lang="scss">
    .handleUser{
        margin: -15px 0;
        .hand-tab{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eee;
            .tab-list{
                font-size: 20px;
                width: 50%;
                text-align: center;
                padding: 15px 0;
                cursor: pointer;
            }
            .tab-active{
                color: #3291f5;
            }
        }
        .hand-content{
            .text{
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
            }
        }
    }
</style>

<template>
    <div class="footer">
        <div class="footer-body">
<!--            <p>公司名称：贵州同天科技有限公司</p>-->
<!--            <p>联系电话：0851-85889038</p>-->
<!--            <p>联系地址：贵州省贵阳市观山湖区金融城二期N1商务楼2904</p>-->
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号：黔ICP备2022003537号-1</a>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss">
    .footer{
        background: #5e5e5e;
        padding: 10px 0;
        .footer-body{
            width: 1200px;
            margin: 0 auto;
            text-align: center;
            p,a{
                color: #fff;
                text-decoration: none;
            }
        }
    }
</style>

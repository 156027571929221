<template>
    <div class="register">
        <div class="register-main">
            <div class="register-form">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" prop="phone">
                        <el-input v-model="ruleForm.phone" placeholder="请输入电话"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号码" prop="idCard">
                        <el-input v-model="ruleForm.idCard" placeholder="请输入身份证号码"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" :style="{marginBottom:'35px'}">
                        <el-input type="password" v-model="ruleForm.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="用户身份" prop="accountType" :style="{marginBottom:'35px'}">
                        <el-select v-model="ruleForm.accountType" :style="{width:'100%'}" placeholder="请选择身份类型">
                            <el-option label="老师" value="2"></el-option>
                            <el-option label="家长" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机验证码" prop="smsCode">
                        <el-input v-model="ruleForm.smsCode"  placeholder="请输入手机验证码">
                            <el-button type="primary" slot="append" @click="onCheckPhone">{{codeText}}</el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即注册</el-button>
                        <el-button @click="resetForm('ruleForm')">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script>
    import { register, checkPhone, getRegisterCode } from "@/api/login"
    export default {
        emits:["handleSuccess"],
        data() {
            return {
                codeText:"获取验证码",
                timer:60,
                times:"",
                ruleForm: {
                    name: '',
                    phone: '',
                    idCard: '',
                    password: '',
                    accountType:'',
                    smsCode:"",
                    isEncipher:true,
                },
                rules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, message: '请输入电话号码', trigger: 'change' },
                        {pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur"}
                    ],
                    idCard: [
                        {required: true, message: '请输入身份证号码', trigger: 'change' },
                        {pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                            message: "请输入正确的身份证号码",
                            trigger: "blur"}
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'change' },
                        {pattern: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!~`@.#$%^_&*()? ]).*$/,
                            message: "密码最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符",
                            trigger: "blur"}
                    ],
                    accountType:[
                        { required: true, message: '请选择身份类型', trigger: 'change' },
                    ],
                    smsCode: [
                        {required: true, message: '请输入手机验证码', trigger: 'change' },
                        {pattern: /^[0-9]{6}$/, message: "手机验证码由六位数字组成", trigger: "blur"}
                    ]
                }
            };
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        register(this.ruleForm).then(res=>{
                            if(res.code == 200){
                                this.resetForm(formName);
                                this.$message({
                                    message: res.msg,
                                    type: 'success'
                                });
                            }else{
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'warning'
                                });
                            }
                        }).catch(() => {});
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit("handleSuccess",false)
            },
            //验证手机号码是否注册
            onCheckPhone(){
                checkPhone(this.ruleForm.phone).then(res=>{
                    if(res.data){
                        this.$message.error('手机号码已注册');
                    }else{
                        this.getPhoneCode()
                    }
                }).catch(() => {});
            },
            //获取手机验证码
            getPhoneCode(){
                this.times= setInterval(this.onSetInterval,1000);
                getRegisterCode(this.ruleForm.phone).then(res=>{
                    this.ruleForm.smsCode = res.data
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                }).catch(() => {});
            },
            //定时器
            onSetInterval(){
                if(this.timer>0){
                    this.timer--;
                    this.codeText = this.timer+"秒后重新获取"
                }else{
                    this.timer = 60
                    clearInterval(this.times);
                    this.codeText="重新获取验证码"
                }

            }
        }
    }
</script>

<style lang="scss">
    .register{
        .register-main{
            background: #fff;
            border-radius: 10px;
            .register-form{
                padding: 15px;
            }
        }
    }
</style>

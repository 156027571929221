import Cookies from "js-cookie";
const TokenKey = "w-Access-token";
const userInfo = "user";

//保存登录后的token
export function setToken(data) {
    return Cookies.set(TokenKey, data);
}
//获取登录的token
export function getToken() {
    return Cookies.get(TokenKey);
}
//删除登录的token
export function removeToken() {
    return Cookies.remove(TokenKey);
}
//保存用户信息
export function setUserInfo(data) {
    return Cookies.set(userInfo, data);
}
//获取用户信息
export function getUser() {
    return Cookies.get(userInfo);
}
//删除用户信息
export function removeUserInfo() {
    return Cookies.remove(userInfo);
}

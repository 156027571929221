import request from '@/util/request'

//获取用户信息
export function getUserInfo (data) {
    return request({
        url: '/api/auth/getInfo',
        method: 'get',
        data:data,
    })
}



//获取当前登录账号的所有学生信息
export function getAllStudent (data) {
    return request({
        url: '/api/student/getAll',
        method: 'get',
        data:data,
    })
}
//添加学生基础信息
export function addStudent (data) {
    return request({
        url: '/api/student',
        method: 'post',
        data:data,
    })
}
//修改学生基础信息
export function editStudent (data) {
    return request({
        url: '/api/student',
        method: 'PUT',
        data:data,
    })
}
//删除学生信息
export function deleteStudent (data) {
    return request({
        url: '/api/student/'+data.id,
        method: 'DELETE',
        data:data,
    })
}


//添加报名信息
export function addEnroll (data) {
    return request({
        url: '/api/enroll',
        method: 'post',
        data:data,
    })
}
//获取学生报名信息
export function getEnroll (data) {
    return request({
        url: '/api/enroll/list',
        method: 'get',
        params:data
    })
}
//删除学生报名信息
export function deleteEnroll (data) {
    return request({
        url: '/api/enroll/'+data.id,
        method: 'delete',
        data:data
    })
}

//获取订单信息
export function getOrder (data) {
    return request({
        url: '/api/pay/createPreOrder/'+data.enrollId,
        method: 'post',
        data:data
    })
}

//获取支付二微码
export function getImgCode (data) {
    return request({
        url: '/api/pay/createOrder',
        method: 'post',
        data:data
    })
}
//查询订单状态
export function orderStatus (data) {
    return request({
        url: '/api/pay/queryOrderStatus/'+data.orderNo,
        method: 'get',
        data:data
    })
}
//订单退款申请
export function applyRefund (data) {
    return request({
        url: '/api/pay/applyRefund',
        method: 'post',
        data:data
    })
}

//获取查看订单详情信息
export function getOrderDetails (data) {
    return request({
        url: '/api/pay/getLastOrderByEnrollId/'+data.enrollId,
        method: 'get',
        data:data
    })
}
